<template>
  <div class="fill-height">
    <!-- :: CONTENT :: -->
    <div v-if="!loading" class="fill-height">
      <div v-if="entity.modules.length > 0">
        <v-sheet v-for="(module,i) in entity.modules" :key="i + 'semester'" :ref="'block_' + i" >

          <!-- Semester Content-->
          <v-simple-table class="wsHoverLight" dense style="border-radius: 15px; !important  "  >

            <!-- Module Info -->
            <thead >
            <tr  style="background-color : transparent">
              <td class="py-2"
                  :style="  (i > 0 ?  `border-top : 1px solid ${wsBACKGROUND} ;` : '') +
              `border-bottom : 1px solid ${wsBACKGROUND}; `" >

                <div class="d-flex align-center justify-lg-space-between">
                  <div class="d-flex align-center py-2">
                    <h3> {{ module.name }}</h3>
                  </div>
                </div>
              </td>

              <td  v-for="(plan,j) in entity.payment_plans" :key="plan + 'plan' + i +j "
                   align="center" width="100px"
                   :style="
              (i > 0 ?  `border-top : 1px solid ${wsBACKGROUND} ;` : '') +
               `border-bottom : 1px solid ${wsBACKGROUND}; `">
                <v-btn @click="editModulePlan(module, plan.value)"
                       :color="module.payment_plan_id.includes(plan.value) ? 'green lighten-2' : 'grey'"
                       icon>
                  <v-icon>{{ getModulePlanIcon(module, plan.value) }}</v-icon>
                </v-btn>
              </td>

            </tr>
            </thead>

            <!-- :: MODULE CONTENT :: -->
            <tbody>
            <template  v-for="(topic,j) in module.topics">
              <!-- Topic Name  -->
              <tr style="background: transparent" :key="j + 'paymentPlan'">
                <td class="py-4">
                  <h4>{{  topic.name  }}</h4>
                </td>
                <td  v-for="plan in entity.payment_plans" :key="plan + 'plan' + i +j +k "
                     align="center" width="100px">
                  <h5>{{ plan.text  }}</h5>
                </td>
              </tr>

              <!-- COMPONENTS  -->
              <tr  v-for="(component, k) in topic.components" :key="'component' + i + j + k "
                   style="cursor: pointer" >
                <!--  Name -->

                <td  style="border: none">
                  <div class="d-flex align-center py-3"

                  >
                    <v-icon :color="getEntityTypeIconColor(component)" class="mr-2 "> {{ getEntityTypeIcon(component.entity_type ) }}</v-icon>
                    <h4 >{{  component.name  }}</h4>
                  </div>
                </td>

                <td  v-for="plan in entity.payment_plans" :key="plan + 'plan' + i +j +k "
                     style="border: none" align="center">
                  <v-btn @click="editComponentPlan(component, plan.value)"
                         :color="getComponentPlanColor(component, plan.value)"
                         icon>
                    <v-icon  >{{ getComponentPlanIcon(component, plan.value) }}</v-icon>
                  </v-btn>
                </td>

              </tr>

            </template>
            </tbody>
          </v-simple-table>

        </v-sheet>
      </div>
    </div>
    <v-sheet min-height="70" v-else class="fill-height d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>


  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "PageCourses",
  props : ['uuid'],
  components : {
  },
  data() {
    return {
      loading : false,

      uploadLogo : false,
      logoUrl : null,

      items : [],

      selectedTopic : null,
      selectedModule : null,

      editNameModule : false,

      newEntity  : true,
      displayDialog : false,
      displayTopicDialog : false,
      displayModuleDialog : false,
      entity : {
        name : "",
        components : [],
        modules : [],
        payment_plans : []
      },
      entityData : {
        name : {},
        payment_plans : []
      },
      entityDataDefault : {
        name : { },
        alias : ''
      },
      defaultComponent : {
        name : { },
        topic_id : null,
      },

      fileUploaded : false,
      selectedFile : {},
      redirect : false,
    }
  },
  computed : {
    ...mapState('courses',[ 'selectedLang']),

    showEntityTypesSelectCondition() {
      return !( this.entityData.file || this.entityData.lecture || this.entityData.task  )
    },

    entityTypes() {
      return [
        { text : this.$t('None'), value: null },
        { text : this.$t('Lecture'), value: 'lecture' },
        { text : this.$t('Task'), value: 'task' },
        { text : this.$t('File'), value: 'file' },
      ]
    },

    actionTypes() {
      return [
        { text : this.$t('CreateNew')  , value: 'new'     }
      ]
    },

    saveText() {
      return this.newEntity ? this.$t('Add') : this.$t('Save')
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_COURSE_EDIT',
        'GET_COMPONENT',
        'EDIT_COMPONENT_PAYMENT',
        'EDIT_MODULE_PAYMENT'
    ]),

    editComponentPlan(component,plan) {
      if ( !component.payment_plan_id.includes(plan) ) {
        component.payment_plan_id.push(plan)
      } else {
        let index = component.payment_plan_id.findIndex(el => el === plan)
        component.payment_plan_id.splice(index,1)
      }
      this.entityData = {
        uuid : component.uuid,
        payment_plan_id : component.payment_plan_id
      }
      this.editComponent()
    },
    getComponentPlanIcon(component,plan) {
      if ( component.payment_plan_id.includes(plan) ) {
        return 'mdi-checkbox-marked-outline'
      }
      return 'mdi-checkbox-blank-outline'
    },

    async editModulePlan(module,plan) {
      let action = '';
      if ( !module.payment_plan_id.includes(plan) ) {
        module.payment_plan_id.push(plan)
        action = 'add'
      } else {
        let index = module.payment_plan_id.findIndex(el => el === plan)
        module.payment_plan_id.splice(index,1)
        action = 'remove'
      }

      this.entityData = {
        uuid : module.uuid,
        payment_plan_id : plan,
        action : action
      }
      await this.editModule()

      module.topics.forEach(topic => {
        topic.components.forEach(component => {

          if ( module.payment_plan_id.includes(plan) ) {
            if ( !component.payment_plan_id.includes(plan) ) {
              component.payment_plan_id.push(plan)
            }
          } else {
            if ( component.payment_plan_id.includes(plan) ) {
              let index = component.payment_plan_id.findIndex(el => el === plan)
              component.payment_plan_id.splice(index,1)
            }
          }

        })

      })

    },
    getModulePlanIcon(module,plan) {
      if ( module.payment_plan_id.includes(plan) ) {
        return 'mdi-checkbox-marked-outline'
      }
      return 'mdi-checkbox-blank-outline'
    },
    getComponentPlanColor(component,plan) {
      if ( component.payment_plan_id.includes(plan) ) {
        return 'green lighten-2'
      }
      return 'grey'
    },

    async displayComponentEdit(uuid) {
      this.newEntity = false
      this.entityData = JSON.parse(JSON.stringify(await this.GET_COMPONENT(uuid) || this.entityDataDefault))
      this.displayDialog = true
    },

    async editModule() {
      let data = await this.EDIT_MODULE_PAYMENT(this.entityData)
      if (!data) {
        return this.notify("ConnectionError")
      }

    },

    async editComponent() {
      let data = await this.EDIT_COMPONENT_PAYMENT(this.entityData)
      if (!data) {
        return this.notify("ConnectionError")
      }

    },

    // Technical
    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getEntityTypeIconColor(  ) {

      return  this.wsATTENTION

    },
    scrollToModule(index) {
      this.$vuetify.goTo(this.$refs['module_' + index][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },



    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
    },

    // Page init
    async initPage(){
      this.flushEntity()

      this.entity = await this.GET_COURSE_EDIT({ uuid : this.uuid, lang : this.selectedLang}) || [] ;
      this.loading = false
      this.$store.state.courses.modules = this.entity.modules

    }
  },

  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>