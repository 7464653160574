<template>
  <sub-page
      sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('PaymentPlans')"
      icon="mdi-cash-multiple"
      :header-action-text="$t('AddPaymentPlan')"
      :header-action="displayNewItem"
      :header-action-condition="items.length > 0"
      :loading="loading"
      fill-height
  >

    <template #default>
      <ws-accordion
          v-if="items.length > 0" class="mt-6"
          :items="navigationSelect"
      >
        <template #item.plans>
          <ws-data-table
              v-if="items.length > 0"
              :items="items"
              :headers="headers"
              hide-default-header
              hide-default-footer
              disable-pagination
              :row-action="displayEditItem"
          >

            <template #item.name="{item}">
              <h5 class="linkHover" :style="`color: ${wsACCENT}`"  >{{ item.name }}</h5>
            </template>

            <template #item.price="{item}">
              <h5 class="text-no-wrap">{{ !item.is_free ? (item.price + ' ' + BUSINESS_CURRENCY) : $t('Free') }}</h5>
            </template>

            <template #item.has_access_rules="{item}">
              <h5 class="text-no-wrap">{{ getAccessRulesText(item) }}</h5>
            </template>

            <template #item.days="{item}">
              <div>
                <h5 class="text-no-wrap"  v-if="item.has_access_rules">
                  {{ item.access_days ? item.access_days : $t('Unlimited') }}
                </h5>
              </div>
            </template>

            <template #item.limit_certificates="{item}">
              <div class="d-flex justify-center">
                <v-icon class="mr-5" :color="!item.limit_certificates ? wsACCENT : 'grey'">mdi-certificate</v-icon>
              </div>
            </template>

            <template #item.is_hidden="{item}">
              <div class="d-flex justify-center">
                <v-icon :color="!item.is_hidden ? wsACCENT : null">
                  {{ !item.is_hidden ? 'mdi-eye' : 'mdi-eye-off' }}
                </v-icon>
              </div>
            </template>


          </ws-data-table>
        </template>

        <template #item.content>
          <coursePaymentPlansEditor :uuid="uuid" />
        </template>

      </ws-accordion>
      <div v-else
           class="wsRoundedLight
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6 " >

        <v-sheet  width="600">

          <div class="d-flex justify-center">
            <v-icon size="80" :color="wsDARKLIGHT" >mdi-cash-multiple</v-icon>
          </div>
          <h3 style="font-size: 22px; font-weight: 700" class="text-center  my-6">{{ $t('NoPaymentPlansMessage') }}</h3>
          <div class="d-flex justify-center">
            <v-btn height="40" @click="displayNewItem" dark :color="wsATTENTION" class="noCaps"> {{ $t('AddPaymentPlan') }}</v-btn>
          </div>
        </v-sheet>

      </div>
    </template>

    <template #dialog>
      <!-- Add / Edit Item Dialog Window -->
      <wsDialog v-model="displayDialog"
                :loading="$store.state.LOADING"
                @save="saveChanges"
                :title="newEntity ? $t('AddPaymentPlan') : $t('EditPaymentPlan')"
                :show-delete="!newEntity"
                @delete="deleteItem"
                :saveText="saveText"
      >

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
            :hide-details="!nameError"
            :error="!!nameError"
            @input="nameError = false"
            :error-messages="nameError"
        />
        <ws-text-field
            v-if="HAS_ONLINE_PAYMENT"
            v-model="entityData.plan_product_id"
            :label="$t('FiscalizationId')"
            :placeholder="$t('EnterFiscalizationId')"
            :tooltip="$t('FiscalizationIdTooltip')"
            class="mt-5"
            clearable
        />

        <ws-text-field
            v-model="entityData.description"
            :label="$t('Description')"
            :placeholder="$t('Description')"
            :hide-details="!nameError"
            :error="!!nameError"
            @input="nameError = false"
            :error-messages="nameError"
            area
            class="mt-5"
        />

        <ws-toggle
            v-model="entityData.is_free"
            :label="$t('Payment')"
            :text-true="$t('Free')"
            :text-false="$t('Payed')"
            class="mt-3"
        />
        <v-expand-transition>
          <ws-text-field
              v-if="!entityData.is_free"
              v-model="entityData.price"
              :label="$t('Price')"
              :placeholder="$t('Price')"

              :hide-details="!priceError"
              :error="!!priceError"
              @input="priceError = false"
              :error-messages="priceError"

              hide-number-handle number
              class="mt-3"
          >
            <template #append>
              <h5 class="mt-2"> {{ BUSINESS_CURRENCY }} </h5>
            </template>
          </ws-text-field>
        </v-expand-transition>

        <ws-toggle
            v-model="entityData.has_access_rules"
            :label="$t('ContentAccessLimits')"
            :text-true="!course.online ? $t('LimitedByDays') : $t('DaysAfterFinish')"
            :text-false="course.online ? $t('AccordingToSchedule') : $t('Unlimited')"
            class="mt-3"
        />
        <h5 :style="`color : ${wsDARKLIGHT}`" class="mt-3">{{$t('ContentAccessLimitsDescription')  }}</h5>

        <v-expand-transition>
          <ws-text-field
              v-if="entityData.has_access_rules"
              v-model="entityData.access_days"
              :label="course.online ? $t('AccessDaysAfterSchedule') : $t('TotalDays')"
              :placeholder="$t('Days')"
              number hide-number-handle
              class="mt-3"
          >
            <template #append>
              <h5 class="mt-2"> {{ $t('Days')}}  </h5>
            </template>
          </ws-text-field>
        </v-expand-transition>

        <v-row no-gutters class="mt-2">
          <v-col class="pr-1">
            <ws-switch
                @input="entityData.is_hidden = !$event"
                :value="!entityData.is_hidden"
                :placeholder="$t('Active')"
                :small="false"
            />
          </v-col>
          <v-col class="pl-1">
            <ws-switch
                v-if="$store.state.courses.course.has_certificates"
                v-model="entityData.limit_certificates"
                :placeholder="$t('certificates.paymentplans.limit.title')"
                :tooltip="$t('certificates.paymentplans.limit.text')"
            />
          </v-col>

        </v-row>

      </wsDialog>
    </template>


  </sub-page>
</template>

<script>
import coursePaymentPlansEditor
  from "@/components/pages/westudy/admin/course/coursePaymentPlansEditor";
import {mapActions, mapMutations,  mapState} from "vuex";

export default {
  name: "courseCalendar",
  props : ['uuid'],
  components : {
    coursePaymentPlansEditor
  },
  data() {
    return {
      items : [],
      loading : true,
      entityDataDefault : {
        name : '',
        price : 100,
        is_free : false,
        has_access_rules : false
      },
      entityData : {

      },
      displayDialog: false,
      newEntity : true,
      nameError : null,
      priceError : null,

    }
  },
  computed : {
    ...mapState('courses',['course','selectedLang']),
    navigationSelect() {
      return [
        {
          name : this.$t('PaymentPlans') ,
          subtitle : this.$t('PaymentPlansSubtitle') ,
          value : 'plans',
          icon : 'mdi-cash-multiple'
        },
        {
          name : this.$t('PaymentPlansEditor') ,
          subtitle : this.$t('PaymentPlansEditorSubtitle') ,
          icon : 'mdi-checkbox-multiple-outline',
          value : 'content',
          expanded : true,

        },
      ]
    },
    paymentTypesSelect() {
      return [
        { text : this.$t('Payed') , value : false },
        { text : this.$t('Free') , value : true }
      ]
    },
    accessTypesSelect() {
      return [
        { text : this.course.online ? this.$t('AccordingToSchedule') : this.$t('Unlimited') , value : false },
        { text : !this.course.online ? this.$t('LimitedByDays') : this.$t('DaysAfterFinish') , value : true }
      ]
    },
    saveText() {
      return this.newEntity ? this.$t('Add') : this.$t('Save')
    },
    headers(){
      let items =  [
        { text : this.$t('Name')                   , value : 'name'  },
        { text : this.$t('ContentAccessLimitType') , value : 'has_access_rules' , width : 10  },
        { text : this.$t('Days')                   , value : 'has_access_rules' , width : 10  },
        { text : this.$t('Price')                  , value : 'price'            , width : 10  },
      ]

      if ( this.$store.state.courses.course.has_certificates) {
        items.splice(1,0, { text : this.$t('Certificates') , value : 'limit_certificates' , width : 10 } )
      }

      items.push( { text : this.$t('Active') , value : 'is_hidden' , width : 10 } )

      return items
    } ,
  },
  watch : {
    selectedLang() {
      this.initPage()
    },
    course() {
      if ( this.items.length > 0 ) {
        this.INIT_PAGE({
          icon : 'mdi-cash-multiple',
          title : this.$t('PaymentPlans'),
          button : this.$t('AddPaymentPlan'),
          action : this.displayNewItem
        })
      }
    },
  },
  methods : {

    ...mapActions('courses',[
          'GET_PAYMENT_PLANS',
          'ADD_PAYMENT_PLAN',
          'GET_PAYMENT_PLAN',
          'DELETE_PAYMENT_PLAN']),

    ...mapMutations('courses',[ 'INIT_PAGE']),

    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
      this.entityData.lang = this.selectedLang
    },
    saveChanges() {

      if ( !this.entityData.name ) {
        this.notify(this.$t('EnterPaymentPlanName') , 'warning')
        this.nameError = this.$t('EnterPaymentPlanName')
        return
      }
      if ( !this.entityData.price && !this.entityData.is_free ) {
        this.notify(this.$t('EnterPrice') , 'warning')
        this.priceError = this.$t('EnterPrice')
        return
      }



      this.entityData.lang = this.selectedLang

      this.newEntity ? this.addItem(this.ADD_PAYMENT_PLAN(this.entityData))
          : this.changeItem(this.ADD_PAYMENT_PLAN(this.entityData))
      this.displayDialog = false
      this.flushEntity()

    },
    deleteItem() {

      this.removeItem(this.DELETE_PAYMENT_PLAN(this.entityData.uuid) )
      this.displayDialog = false

    },

    async addItem(item) {
      let result = await item
      if (!result) { return this.notify( this.$t('NetworkError') ) }
      this.INIT_PAGE({
        icon : 'mdi-cash-multiple',
        title : this.$t('PaymentPlans'),
        button : this.$t('AddPaymentPlan'),
        action : this.displayNewItem
      })
      this.items.push(result)
      this.notify( this.$t('NewPaymentPlanCreated') )
    },
    async changeItem(item) {
      let result = await item

      if (!result) { return this.notify( this.$t('NetworkError') ) }

      var index = this.items
          .findIndex(el => el.uuid === result.uuid )
      if (index === -1 ) { return }

      this.items[index] = result
      this.$set(this.items,index,result)
      this.notify( this.$t('ChangesSaved') )
    },
    async removeItem(deleteAction) {

      if ( !await deleteAction ) {
        return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      var index = this.items
          .findIndex(el => el.uuid === this.entityData.uuid )
      if (index === -1 ) { return }
      this.items.splice(index,1)
      this.notify(this.$t('PaymentPlanDeleted'))

    },

    displayNewItem(){
      this.flushEntity()
      this.newEntity = true
      this.entityData.course_id = this.uuid
      this.displayDialog = true

    },
    async displayEditItem(item) {
      this.displayDialog = true;
      this.newEntity = false
      this.entityData = await this.GET_PAYMENT_PLAN({uuid : item.uuid , lang : this.selectedLang })
      this.entityData.course_id = this.uuid
      //    this.entityData = JSON.parse(JSON.stringify(this.entityData))
    },

    // Technical
    getAccessRulesText(item) {
      if ( this.course.online) {
        return  item.has_access_rules ? this.$t('DaysAfterFinish') : this.$t('AccordingToSchedule')
      } else {
        return  item.has_access_rules ? this.$t('LimitedByDays') : this.$t('Unlimited')
      }
    },

    async initPage() {
      this.flushEntity()
      let result = await this.GET_PAYMENT_PLANS({ uuid : this.uuid , lang : this.selectedLang });
      if ( !result || result === true ) {
        this.loading = false
        return
      }

      this.loading = false
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>